.headerTitle{
    padding-top: 10px;
    padding-left: 20px;
}


.action-button{
        width: 110px;
        font-size: 18px;
        font-weight: bold;
        background-color: blue;
        color: white;
        justify-content: center;
        align-items: center;
        display: flex;
        margin-top: -7px;
        padding-top:5px;
        padding-bottom:5px;
        margin-left: 10px;
}
