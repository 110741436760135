.subscriptionList {
padding-left: 20px;
padding-top: 20px;
padding-bottom: 20px;
}

.sessions-renderer .item.session:first-of-type{
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;   
}

.sessions-renderer .item.session:last-of-type{
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;   
}

.dark .session.item {
    border-color: aliceblue !important;
}

.dark .subscription-info h2{
    color:lightgrey;
}

.dark .subscription-info h3{
    color:grey;
}

.dark .session-completed{
    background-color: lightblue;
}

.subscriptionItem{
    margin-bottom: 10px;
}

.subscriptionItem .sessions-renderer{
    margin-top: 0px;
}

.session-completed{
    background-color: yellow;
}

.subscription-info{
    width:700px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.subscription-info h2,
.subscription-info h3{
    color:#222;
    font-family: 'Nunito', sans-serif;
}

.subscription-info h2{
    font-weight: bold;
    text-transform: uppercase;
}


.incrementer-button{
    width: 50px;
    height: 50px;
    font-size: 30px;
    font-weight: bold;
    background-color: blue;
    color: white;
    margin-left: 20px;
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 0px;
    margin-top: 0px;
}

.dark .incrementer-button:hover{
    background-color: yellow;
    color:black;
}

.session-container-with-action{
    display: flex;
    flex-direction: row;
    align-items: center;
}