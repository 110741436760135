
.message-header{
    border-bottom: 1px solid #000;
    margin-right: 20px;
    margin-bottom: 20px;
}

.message-content h3{
    margin-top:5px;
    display: flex;
    width:100%;
}


.left-ico{
    width:150px;
    display: flex;
    justify-content: space-between;
}

.msg{
    font-size: 20px;
    color:#000;
}
.dark .msg{
    color:#FFF;
}

.msgbox-container{
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    border-radius: 5px;
    border:1px solid lightcyan;
    padding:10px;
    margin-right: 20px;
}

.msg-val{
    font-weight: bold;
    padding-left: 10px;
    font-size: 18px;
    font-family: 'Nunito', sans-serif
}