
.errorsContainer{
    box-sizing: content-box;
    width: 100%;
    
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  
}   

.error {
    padding-left: 40px;
    color:white;
    font-size: 17px;
    background-color: red;
    font-weight: bold;
    margin-top: 5px;
    margin-bottom: 5px;
}

.mt-5{
   margin-top:15px; 
}

.newContainer form div.datepicker button:hover,
.newContainer form div.timepicker button:hover{
    background: transparent !important;
    border: none;
}
.servicetypeContainer {
    padding-bottom: 40px !important;
    margin-top: 0px !important;
}

.servicetypeContainer .servicetype {
    height: 90px;
    width: 50%;
    display: flex;
    justify-content: center !important;
    align-items: center;
}

.servicetypeContainer .servicetype h4 {
    display: block;
}

.servicetypeContainer .servicetype:hover {
    cursor: pointer;
}

.servicetypeContainer .servicetype.selected {
    background-color: black;
    color: #FFF;
}

.new {
    display: flex;
    width: 100%;
    
    .deleteReservationService{
        margin-left:20px;
        width: 50px;
        height: 50px;
        margin-top: 0px;
        padding:0 !important;
    }
    
    .AddAnotherServicer-container{
        margin-top: 20px;
        border-top: 1px solid #DDD;
        display: flex;
        justify-content: flex-end !important;
        align-items: flex-end;   
    }

    .AddAnotherServicer-container button{
        width:200px;
    }
    .services-container{
        display: flex;
        flex-direction: column;
    }
    .service-rows{
        display: flex;
        flex-direction: row;
        width: 100%;
    }
    .newContainer {
        flex: 6;

        header {
            margin:0px auto;
            margin-top:10px;
            display:flex;
            width: 96%;
            font-size:1.2em;
            align-self: center;

        }

        .addNewReservation{

            width: 94%;
            margin: 0px auto;
            margin-top: 20px;
            align-self: center;
            display: flex;
            justify-content: center;

            .errorContainer {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 20px 0px 20px 0px;
            }

            .errorContainer p {
                color: red;
            }

            label {
                width: 20%;
            }

            div {
                width: 100%;
                display: flex;
                justify-content: flex-start;
                align-items: center;

                select {
                    min-height: 60px;
                    margin-bottom: 10px;
                    outline: none;
                    border: none;
                }

                select,
                input {
                    width: 100%;
                }
            }
        }

        .formActionsConatiner {
            display: flex;
            justify-content: flex-end;
            padding-right: 40px;
        }

        .anotherCategory {
            width: 150px;
        }

        .top,
        .bottom {
            -webkit-box-shadow: 2px 4px 10 px 1px rgba(0, 0, 0, 0.47);
            box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
            padding: 10px;
            margin: 20px;
            display: flex;

            h1 {
                color: lightgray;
                font-size: 20px;
            }

            .left {
                flex: 1;
                text-align: center;

                img {
                    width: 100px;
                    height: 100px;
                    border-radius: 50%;
                    object-fit: contain;
                }
            }

            .right {
                flex: 2;

                form {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 25px;
                    justify-content: space-around;

                    .formInput {
                        width: 40%;

                        label {
                            display: flex;
                            align-items: center;
                            gap: 10px;

                            .icon {
                                cursor: pointer;
                            }
                        }

                        input {
                            width: 100%;
                            padding: 5px;
                            border: none;
                            border-bottom: 1px solid gray;
                        }
                    }

                    button {
                        width: 150px;
                        padding: 10px;
                        border: none;
                        background-color: teal;
                        color: white;
                        font-weight: bold;
                        cursor: pointer;
                        margin-top: 10px;
                    }
                }
            }
        }
    }
}