

.view-reservation{
    background-color: transparent;
    border:none;
    width: 40px;
    height: 40px;
    padding:0px;
    margin: 0px;
}


.view-reservation svg{
    color:blue;
    transition: all .3;
}

.view-reservation:hover{
    border:none;
}

.view-reservation:hover svg{
    color: black;
}

.originateFrom{
    text-align: center;
    padding-top: 17px;
    font-weight: 900;
    font-size: xx-small;
    font-size: .8em;
    display: block;
    width: 100%;
}


