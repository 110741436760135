
.reservation-header {
    display: flex;
    justify-content: space-between;
    padding-right: 20px;
}

.reservation-content h2{
    margin-bottom: 0px;
}

.mark-as{
    margin-right: 10px;
    color:darkcyan;
}

.btn-left{
    margin-right:5px
}

.reservation-header button {
    width:150px;
    height:30px;
    line-height:0;
}

.reservation-header button:disabled:hover{
    background-color: black;
    color:#FFF;
}

.reservation-header button:disabled{
    opacity:.8;
    cursor:default !important
}

.btn-new{
    background-color: blue;
}

.dark {
    .mark-as{
        margin-right: 10px;
        color:aquamarine;
    }

    .reservation-header button{
        background-color: white;
        color:black;
    }
    .reservation-header button:hover{
        border-color: white;
        color:white;
        background-color:transparent;
    }

    .reservation-header button:disabled{
            background-color: white;
            color:black;
    }
}