.dark {
    background-color: #111;
    color: rgb(156, 156, 156);

    .chartGrid {
        stroke: rgba(228, 288, 228, 0.219);
    }

    a {
        color: #CD6C00;
        text-decoration: none;
        transition: all .2s;
    }

    a:hover {
        color: #FFF;
    }

    .table {
        background-color: #111;

        .tableCell {
            color: gray;
        }
    }

    .navbar {
        color: #999;
        border-color: #333;

        .search {
            border-color: gray;
        }
    }

    .sidebar {
        background-color: #111;
        border-color: #333;

        .top {
            .logo {
                color: #FCD600;
            }
        }

        hr {
            border-color: #333;
        }

        ul {
            li {
                &:hover {
                    background-color: #333;
                }

                .icon {
                    color: #999;
                }
            }
        }
    }

    .datatable {
        .datagrid {
            color: gray;
            border: none;

            .viewButton,
            .deleteButton,
            .cellWithStatus {
                color: gray;
                border: none;
            }
        }
    }

    input {
        background-color: transparent;
    }


    .userCard.selected {
        background-color: #FFF;
        color: #212121;
    }

    .userCard.selected svg {
        color: #000;
    }

    .rocordedServicesBottomLine {
        .totaleContainer {
            border-top: 2px solid rgba(255, 255, 255, 0.1);
        }
    }

}