.new {
    display: flex;
    width: 100%;

    .newContainer {
        flex: 6;

        .addNewService {
            width: 80%;
            margin: 0px auto;
            margin-top: 20px;
            align-self: center;
            display: flex;
            justify-content: center;

            .errorContainer {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 20px 0px 20px 0px;
            }

            .errorContainer p{
                color: red;
            }

            label {
                width: 20%;
            }

            div {
                width: 100%;
                display: flex;
                justify-content: flex-start;

                select {
                    min-height: 60px;
                    margin-bottom: 10px;
                    outline: none;
                    border: none;
                }

                select,
                input {
                    width: 100%;
                }
            }
        }

        .formActionsConatiner {
            display: flex;
            justify-content: flex-end;
            padding-right: 40px;
        }

        .anotherCategory {
            width: 150px;
        }

        .top,
        .bottom {
            -webkit-box-shadow: 2px 4px 10 px 1px rgba(0, 0, 0, 0.47);
            box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
            padding: 10px;
            margin: 20px;
            display: flex;

            h1 {
                color: lightgray;
                font-size: 20px;
            }

            .left {
                flex: 1;
                text-align: center;

                img {
                    width: 100px;
                    height: 100px;
                    border-radius: 50%;
                    object-fit: contain;
                }
            }

            .right {
                flex: 2;

                form {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 25px;
                    justify-content: space-around;

                    .formInput {
                        width: 40%;

                        label {
                            display: flex;
                            align-items: center;
                            gap: 10px;

                            .icon {
                                cursor: pointer;
                            }
                        }

                        input {
                            width: 100%;
                            padding: 5px;
                            border: none;
                            border-bottom: 1px solid gray;
                        }
                    }

                    button {
                        width: 150px;
                        padding: 10px;
                        border: none;
                        background-color: teal;
                        color: white;
                        font-weight: bold;
                        cursor: pointer;
                        margin-top: 10px;
                    }
                }
            }
        }
    }
}