.purchase-mode-container {
    display: flex;
    flex-direction: column;
}


.rounded.btn {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    margin: 0px 10px 0px 10px;
    padding: 0px;
}



.btn.transparent {
    background-color: transparent;
}

.btn.search:hover {
    background-color: #000;
}

.btn.search:hover svg {
    color: #FFF;
}

.clientsList {
    display: flex;
    flex-direction: row;
    padding-left: 30px;
    margin-top: 15px;
}


.userCard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #e4e1e1;
    padding: 10px;
}

.userCard span {
    font-family: 'Nunito', sans-serif;
}

.userCard span:first-of-type {
    font-weight: bold;
}

.userCard:hover {
    cursor: pointer;
}

.userCard:not(:first-child) {
    margin-left: 10px;
}

.userCard.selected {
    background-color: #000;
    color: #FFF;
}

.userCard.selected svg {
    color: #FFF;
}

.rocordedServicesBottomLine {
    display: flex;
    padding-left: 30px;
    flex-direction: column;
    width: 93%;

    .divider {
        margin-top: 20px;
    }

    header,
    .totaleContainer,
    li {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    main {
        margin-top: 20px;
    }

    .totaleContainer {
        padding-top: 15px;
        margin-top: 15px;
        border-top: 2px solid #000;
    }
}

.actionButtons .new_subsc:disabled {
    opacity: .8;
}

.actionButtons .new_subsc:disabled:hover {
    cursor: auto;
    background-color: #000;
    color: #fff;
}

.actionButtons {
    padding-bottom: 15px;
    display: flex;
    width: 100%;
    justify-content: flex-end !important;
    margin-bottom: 10px;
    outline: 1px solid #DDD;
}

.actionButtons .new_subsc {
    width: 120px;
    margin-right: 15px;
}


.multipleServiceSessionDisplaycontainer {
    display: flex;
    flex-direction: column;

}

.multipleServiceSessionDisplaycontainer h2,
h3 {
    align-self: flex-start;
    height: 20px;
    padding: 0px;
    margin: 0px;
}

.multipleServiceSessionDisplaycontainer span {
    display: inline;
}

.multipleServiceSessionDisplaycontainer h3 {
    margin: 10px 0px 0px 0px;
}


.multipleServiceSessionDisplaycontainer h2 span.servicename,
span.price {
    color: #000
}

.sessions-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start !important;
    align-items: flex-start !important;
    margin-top: 20px;
    border-top: 1px solid #DDD;
}

.sessions-container h4 {
    color: #000;
    margin-top: 20px;
    font-weight: 400;
}

.sessions-renderer {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .session.item {
        width: auto;
        height: 30px;
        border: 1px solid #000;
        border-left: 0px;

    }

    .session.item:first-of-type {
        border-left: 0px;
        border-left: 1px solid #000;
    }

    .session.item:first-of-type.selected {
        background-color: greenyellow;
    }

    .session.item:first-of-type:hover {
        cursor: pointer;
        background-color: greenyellow;
    }

}