.brand {
  font-family: 'Dancing Script', cursive;
  font-size: 4em;
}

.brand-subText {
  font-size: 12px;
  line-height: 10px;
  color: #FCD600;
  margin-top: -30px;
}

.login-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin: 0px auto;
}

form {
  display: flex;
  width: 400px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f7f7f7;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

h2 {
  margin-bottom: 20px;
}

label {
  font-weight: bold;
  margin-bottom: 5px;
  text-align: left;
  display: block;
  width: 100%;
}

input {
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: none;
  display: inline-block;
  width: 100%;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
}

button {
  padding: 10px;
  margin-top: 10px;
  background-color: black;
  color: white;
  border: none;
  border-radius: 5px;
  width: 100%;
  transition: all .2s;
  cursor: pointer;
border:1px solid transparent;
}

button:hover {
  border-color: #000;
  background-color: transparent;
  color: #000;
  border:1px solid #000;
}