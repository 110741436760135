.sidebar{
    flex: 1;
    border-right: 0.5px solid rgb(230,227,227);
    min-height: 100vh;
    background-color: white;

    .top{
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;


    .logo{
        font-size: 20px;
        font-weight: bold;
        color: #ff3d57;
    }

    .logo.branding{
        color:#333;
        font-family:'Dancing Script',sans-serif;
        font-size:2em;
    }

    }

        hr{
            height: 0;
            border: 0.5px solid rgb(230,227,227);
        }

        .center{
            padding-left: 10px;

            ul{
                list-style: none;
                margin: 0;
                padding: 0;

                .title{
                    font-size: 10px;
                    font-weight: bold;
                    color: #999;
                    margin-top: 15px;
                    margin-bottom: 5px;
                }

                li {
                    display: flex;
                    align-items: center;
                    padding: 5px;
                    cursor: pointer;

                    &:hover{
                        background-color: #ece8ff;
                    }

                    .icon{
                        font-size: 18px;
                        color: #ffca00;
                       // color: #7451f8;
                    }

                    span{
                        font-size: 13px;
                        font-weight: bold;
                        color: #888;
                        margin-left: 10px;
                    }
                }
            }
        }

        .bottom{
            display: flex;
            margin: 10px;
  

            .colorOption{
                width: 20px;
                height: 20px;
                border-radius: 5px;
                border: 1px solid #7451f8;
                cursor: pointer;
                margin: 5px;

                &:nth-child(1){
                    background-color: whitesmoke;
                }
                &:nth-child(2){
                    background-color: #333;
                }
            }
        }
        
}