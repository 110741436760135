.widget {
    display: flex;
    justify-content: space-between;
    flex: 1;
    padding: 10px;
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
    height: 100px;

    .addButton,
    .reloadButton{
        background-color: transparent;
        position: absolute;
        top: -12px;
        right: 5px;
        width: 30px;
        height: 30px;
        color: black;
        outline: 1px solid #000;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .addButton{
        top:30px,
    }

    .addButton:hover,
    .reloadButton:hover{
        border:none;
        background:black;
        color:white;

    }




    .left,
    .right {

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .linkWidgetContainerRight {
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .amountLink,
        .linkInWidget {
            text-decoration: none;
        }

        .title {
            font-weight: bold;
            font-size: 14px;
            color: rgb(135, 123, 123);
        }

        .counter {
            font-size: 28px;
            font-weight: 300;
        }

        .link {
            width: max-content;
            font-size: 12px;
            border-bottom: 1px solid gray;
        }

        .percentage {
            display: flex;
            align-items: center;
            font-size: 14px;

            &.positive {
                color: green;
            }

            &.negative {
                color: red;
            }
        }

        .icon {
            font-size: 18px;
            padding: 5px;
            border-radius: 5px;
            align-self: flex-end;
        }
    }
}


.dark .widget {

    .addButton,
    .reloadButton{
        background-color: transparent;
        position: absolute;
        top: -12px;
        right: 5px;
        width: 30px;
        height: 30px;
        color: white;
        outline: 1px solid #DDD;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .addButton{
        top:30px;
    }

    .addButton:hover,
    .reloadButton:hover{
        border:none;
        background:white;
        color:black;

    }

}